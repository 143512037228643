import "./Heading.css"

const Heading = () => {
    return(
        <div className="mainContainer">
           <div className="headingContainer">
           </div>
           <h1 className="headingName">Portfolio</h1>
        </div>
    )
}

export default Heading;